export function bh(selector) {
  return $('[data-behaviour="' + selector + '"]');
}

export function byEl(selector) {
  return $('[data-element="' + selector + '"]');
}

export function dataString(attribute, selector) {
  return '[data-' + attribute + '="' + selector + '"]';
}

